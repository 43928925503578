<style
  lang="postcss"
  scoped
>
  .position {
    & .iconfont {
      font-size: .18rem;
      padding: .05rem .1rem .01rem;
      display: block;
    }

    & p {
      line-height: 1;
      padding-top: 0.02rem;
      font-size: 0.08rem;
      font-weight: normal;
    }

    & .full-text {
      color: #fff;
      padding: 0.1rem 0;
      text-shadow: 0 1px 1px #666;

      & .iconfont {
        padding: 0;
        font-size: 0.16rem;
        line-height: 1;
      }

      & p {
        font-size: 0.12rem;
        padding-top: 0;
      }
    }
  }

</style>
<template>
  <div
    class="position"
    slot="left"
  >
    <div
      :class="{'flex-left-center': fullText, 'full-text': fullText}"
      @click="selectAddress = !selectAddress"
    >
      <span class="iconfont icon-location"></span>
      <p class="text-1">{{ location }}</p>
      <span
        class="iconfont icon-down"
        v-if="fullText"
      ></span>
    </div>
  </div>
</template>
<script>
  import MyPosition from "./Position"
  import Vue from "vue"

  const baseLocationText = "请选择"
  import router from "@/router"
  import store from "@/store"

  export default {
    name: "Location",
    props: {
      fullText: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        location: baseLocationText,
        selectAddress: false
      }
    },
    methods: {
      initiation () {
        this.setAddr()
        window.eventBus.$on("addressChange", () => {
          this.setAddr()
        })
        // 定位失败弹窗地址选择
        window.eventBus.$on("geolocationFalse", () => {
          this.selectAddress = true
        })
      },
      setAddr () {
        const addr = JSON.parse(this.$cookie.get("address"))
        if (addr && addr.length >= 2) {
          if (this.fullText) {
            this.location = addr.map(item => item.area_name).filter((item, index, arr) => arr.indexOf(item) ===
              index).join("") || baseLocationText
          } else {
            this.location = addr[1]["area_name"] || baseLocationText
          }
        }
      }
    },
    mounted () {
      const containerDivEl = document.createElement("div")
      const bodyEle = document.createElement("div")
      containerDivEl.append(bodyEle)
      document.body.appendChild(containerDivEl)
      this.vNode = new Vue({
        el: bodyEle,
        router,
        store,
        render: h => h(MyPosition, {
          props: {
            geolocation: true,
            isVisible: this.selectAddress
          }
        })
      })
      this.vNode.$mount()
      this.vNode.$children[0].$on("close", () => {
        this.selectAddress = false
      })
      this.initiation()
    },
    destroyed () {
      this.vNode.$destroy()
    }
  }
</script>
